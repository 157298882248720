import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons/faEnvelope';

const data = [
  {
    link: 'https://github.com/AliAwdeh',
    label: 'Github',
    icon: faGithub,
  },
  {
    link: 'https://facebook.com/ali.imad.audi',
    label: 'Facebook',
    icon: faFacebookF,
  },
  {
    link: 'https://www.instagram.com/AliAwdeh1/',
    label: 'Instagram',
    icon: faInstagram,
  },
  {
    link: 'https://www.linkedin.com/in/ali-awdeh-39b752256/',
    label: 'LinkedIn',
    icon: faLinkedinIn,
  },
  {
    link: 'https://twitter.com/AliAwdeh1',
    label: 'Twitter',
    icon: faTwitter,
  },
  {
    link: 'mailto:Ali.i.Awdeh@gmail.com',
    label: 'Email',
    icon: faEnvelope,
  },
];

export default data;
